<template>
  <div>
    <b-modal
      id="modal-pdf-viewer"
      ref="modalPdfViewer"
      title=""
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-title="Close"
      centered
      :hide-footer="true"
      size="lg"
    >
      <pdf-viewer/>
    </b-modal>
    <b-modal
      id="modal-change-paycheck-period-invoice"
      ref="modalChangePaycheckPeriodInvoice"
      cancel-variant="outline-secondary"
      ok-disabled
      cancel-disabled
      cancel-title="Close"
      centered
      title="Verloningsperiode wijzigen"
      :hide-footer="true"
      no-close-on-backdrop
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          @submit.prevent="handleSubmit(handleChangePaycheckPeriode)"
        >
          <b-row>
            <b-col
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="Verloningsperiode"
                rules="required"
              >
                <b-form-group
                  :state="getValidationState(validationContext)"
                >
                  <label>Selecteer een verloningsperiode</label>
                  <v-select
                    id="paycheckPeriodSelected"
                    v-model="paycheckPeriodSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="getPayPeriods()"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              class="text-right"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                <b-spinner
                  v-if="showChangePaycheckPeriodSpinner"
                  small
                  class="mr-1"
                />
                Aanvragen
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-row class="match-height">
      <b-col
        cols="12"
        md="8"
        class="d-flex justify-content-between flex-column"
      >
        <b-overlay
          :show="showOverlay"
          rounded="sm"
        >
          <b-card
            title="Gegevens"
          >
            <!-- User Info: Input Fields -->
            <validation-observer
              #default="{ handleSubmit }"
              ref="refFormObserver"
            >
              <b-row
                v-if="!showOverlay && companyData.link.companyPaysFee && companyData.link.companyPaysFee === 1"
                class="mt-2"
              >
                <b-col
                  cols="12"
                >
                  <b-alert
                    variant="primary"
                    show
                  >
                    <div class="alert-body">
                      <span><b>Fee-overdracht actief:</b> Deze opdrachtgever heeft er voor gekozen om de payrite fee op zich te dragen.
                        De fee wordt daarom bij facturen richting deze opdrachtgever niet in rekening bij jou gebracht.</span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              <b-form
                @submit.prevent="handleSubmit(updateCompany)"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Bedrijfsnaam"
                      rules="required"
                    >
                      <b-form-group
                        label="Bedrijfsnaam"
                        label-for="companyName"
                      >
                        <b-form-input
                          id="companyName"
                          v-model="companyData.name"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="KvK-nr"
                      rules="required"
                    >
                      <b-form-group
                        label="KvK-nr"
                        label-for="cocNumber"
                      >
                        <b-form-input
                          id="cocNumber"
                          v-model="companyData.cocNumber"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Straat"
                      rules="required"
                    >
                      <b-form-group
                        label="Straat"
                        label-for="street"
                      >
                        <b-form-input
                          id="street"
                          v-model="companyData.street"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Huisnummer"
                      rules="required"
                    >
                      <b-form-group
                        label="Huisnummer"
                        label-for="houseNumber"
                      >
                        <b-form-input
                          id="houseNumber"
                          v-model="companyData.houseNumber"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Postcode"
                      rules="required"
                    >
                      <b-form-group
                        label="Postcode"
                        label-for="postalCode"
                      >
                        <b-form-input
                          id="postalCode"
                          v-model="companyData.postalCode"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Plaats"
                      rules="required"
                    >
                      <b-form-group
                        label="Plaats"
                        label-for="city"
                      >
                        <b-form-input
                          id="city"
                          v-model="companyData.city"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Voornaam"
                      rules="required"
                    >
                      <b-form-group
                        label="Voornaam"
                        label-for="firstName"
                      >
                        <b-form-input
                          id="firstName"
                          v-model="companyData.firstName"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Achternaam"
                      rules="required"
                    >
                      <b-form-group
                        label="Achternaam"
                        label-for="lastName"
                      >
                        <b-form-input
                          id="lastName"
                          v-model="companyData.lastName"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="E-mailadres"
                      rules="required|email"
                    >
                      <b-form-group
                        label="E-mailadres"
                        label-for="email"
                      >
                        <b-form-input
                          id="email"
                          v-model="companyData.email"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Telefoon"
                      rules="required|integer"
                    >
                      <b-form-group
                        label="Telefoon"
                        label-for="phone"
                      >
                        <b-form-input
                          id="phone"
                          v-model="companyData.phone"
                          :state="getValidationState(validationContext)"
                          type="text"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="BTW-Nummer"
                      rules=""
                    >
                      <b-form-group
                        label="BTW-Nummer"
                        label-for="vatNumber"
                      >
                        <b-form-input
                          id="vatNumber"
                          v-model="companyData.vatNumber"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="Betaaltermijn"
                      rules="required"
                    >
                      <b-form-group
                        label="Betaaltermijn"
                        label-for="paymentTermDays"
                      >
                        <b-form-input
                          id="paymentTermDays"
                          v-model="companyData.paymentTermDays"
                          :state="getValidationState(validationContext)"
                          type="number"
                        />
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row
                  class="mt-2"
                >
                  <b-col
                    cols="12"
                    class="text-right"
                  >
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      type="submit"
                    >
                      <span>Opslaan</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col
        class="d-flex justify-content-between flex-column"
        cols="12"
        md="4"
      >
        <b-card
          title="Handeling fee (%)"
        >
          <b-card-text>
            De minimale handeling fee is 3%. Dit mag alleen lager ingesteld worden indien dit door de directie van Payrite word doorgegeven.
          </b-card-text>
          <!-- User Info: Input Fields -->
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(updateFee)"
              @reset.prevent="resetForm"
            >
              <b-row class="mt-2">
                <b-col
                  cols="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="commissionPercentage"
                    rules="required|integer|between:2,5"
                  >
                    <b-form-group
                      label="Percentage"
                      label-for="commissionPercentage"
                    >
                      <b-form-input
                        id="companyPaysPercentage"
                        v-model="companyData.commissionPercentage"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  class="text-right"
                >
                  <b-button
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    type="submit"
                  >
                    <span>Aanpassen</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-overlay
          :show="showPaySlipsOverlay"
          rounded="sm"
        >
          <b-card
            title="Facturen"
          >
            <b-table
              :items="invoices"
              :fields="tableInvoicesColumns"
              striped
              responsive
              show-empty
              empty-text="Geen overeenkomende records gevonden"
              class="position-relative"
            >
              <!-- Column: Id -->
              <template #cell(id)="data">
                #{{ data.value }}
              </template>

              <template #cell(status)="data">
                <b-badge :variant="resolveTypeInvoiceVariant(data.value).variant">
                  {{ resolveTypeInvoiceVariant(data.value).status }}
                </b-badge>
              </template>

              <template #cell(companyID)="data">
                {{ data.item.company.name }}
              </template>

              <template #cell(totalAmount)="data">
                &euro; {{
                  parseFloat(data.value)
                    .toFixed(2)
                }}
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="data.item.paycheckProcessed === null || data.item.paycheckProcessed === 0"
                    :id="`paycheck-period-row-${data.item.id}-invoice`"
                    @click="showModalPaycheckPeriode(data.item.id)"
                  >
                    <feather-icon
                      :id="`paycheck-period-row-${data.item.id}-invoice-icon`"
                      class="cursor-pointer"
                      icon="ClockIcon"
                      size="16"
                      style="color: #29625b;"
                    />
                    Verloningsperiode wijzigen
                  </b-dropdown-item>
                  <!--                  <b-dropdown-item-->
                  <!--                    v-if="data.item.status === 11 && profileData.type === 1 || data.item.status === 12 && profileData.type === 2"-->
                  <!--                    :id="`approve-row-${data.item.id}-invoice`"-->
                  <!--                    @click="confirmApproveDeclineInvoice(data.item.status, 1, data.item.id)"-->
                  <!--                  >-->
                  <!--                    <feather-icon-->
                  <!--                      :id="`approve-row-${data.item.id}-invoice-icon`"-->
                  <!--                      class="cursor-pointer"-->
                  <!--                      icon="CheckCircleIcon"-->
                  <!--                      size="16"-->
                  <!--                      style="color: #29625b;"-->
                  <!--                    />-->
                  <!--                    Accepteren-->
                  <!--                  </b-dropdown-item>-->
                  <!--                  <b-dropdown-item-->
                  <!--                    v-if="data.item.status === 11 && profileData.type === 1 || data.item.status === 12 && profileData.type === 2"-->
                  <!--                    :id="`decline-row-${data.item.id}-invoice`"-->
                  <!--                    @click="confirmApproveDeclineInvoice(data.item.status, 0, data.item.id)"-->
                  <!--                  >-->
                  <!--                    <feather-icon-->
                  <!--                      :id="`decline-row-${data.item.id}-invoice-icon`"-->
                  <!--                      class="cursor-pointer"-->
                  <!--                      icon="XCircleIcon"-->
                  <!--                      size="16"-->
                  <!--                      style="color: #29625b;"-->
                  <!--                    />-->
                  <!--                    Afkeuren-->
                  <!--                  </b-dropdown-item>-->
                  <b-dropdown-item
                    :id="`pdf-row-${data.item.id}-preview`"
                    @click="viewDocument(data.item)"
                  >
                    <feather-icon
                      :id="`pdf-row-${data.item.id}-preview-icon`"
                      class="cursor-pointer"
                      icon="EyeIcon"
                      size="16"
                      style="color: #29625b;"
                    />
                    Bekijken
                  </b-dropdown-item>
                  <b-dropdown-item
                    :id="`invoices-row-${data.item.id}-download`"
                    :href="`${axiosDefaultConfig.backendEndPoint}invoices/${data.item.id}/pdf?token=${data.item.pdfToken}`"
                    target="_blank"
                  >
                    <feather-icon
                      :id="`invoices-row-${data.item.id}-download-icon`"
                      class="cursor-pointer"
                      icon="DownloadIcon"
                      size="16"
                    />
                    Downloaden
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col
        cols="12"
        md="12"
        class="d-flex justify-content-between flex-column"
      >
        <b-overlay
          :show="showAgreementOverlay"
          rounded="sm"
        >
          <b-card
            title="Overeenkomsten"
          >
            <b-row class="mt-0 mb-2">
              <b-col
                cols="12"
                md="12"
                class="d-flex align-items-center justify-content-end"
              >
                <!--                <b-button-->
                <!--                  variant="secondary"-->
                <!--                  @click="$refs.modalAgreement.show()"-->
                <!--                >-->
                <!--                  <span class="text-nowrap">Nieuwe overeenkomst aanmaken</span>-->
                <!--                </b-button>-->
              </b-col>
            </b-row>
            <b-table
              :items="agreements"
              :fields="tableAgreementsColumns"
              striped
              responsive
              show-empty
              empty-text="Geen overeenkomst(en) gevonden"
              class="position-relative"
            >
              <!-- Column: Id -->
              <template #cell(id)="data">
                #{{ data.value }}
              </template>
              <template #cell(status)="data">
                {{ resolveTypeAgreementVariant(data.value).status }}
              </template>
              <template #cell(companyConfirmTimestamp)="data">
                {{ data.value ? formatDateTime(data.value) : 'In afwachting' }}
              </template>
              <template #cell(endDate)="data">
                {{ data.value ? data.value : 'Onbepaalde tijd' }}
              </template>
              <template #cell(actions)="data">
                <div class="text-nowrap">
                  <feather-icon
                    :id="`pdf-row-${data.item.id}-preview-icon`"
                    class="cursor-pointer mx-1"
                    icon="EyeIcon"
                    size="16"
                    style="color:#fdb813"
                    @click="viewAgreement(data.item)"
                  />
                  <b-link
                    :href="`${axiosDefaultConfig.backendEndPoint}agreements/${data.item.id}/pdf?token=${data.item.pdfToken}`"
                    target="_blank"
                  >
                    <feather-icon
                      :id="`agreement-row-${data.item.id}-download-icon`"
                      class="cursor-pointer"
                      icon="DownloadIcon"
                      size="16"
                      style="color:#82868b"
                    />
                  </b-link>
                  <!--                  <feather-icon-->
                  <!--                    v-if="userData.type === 1 && data.item.status == 5 || userData.type === 2 && data.item.status == 6"-->
                  <!--                    :id="`agreement-row-${data.item.id}-approval-icon`"-->
                  <!--                    class="cursor-pointer ml-1"-->
                  <!--                    icon="CheckCircleIcon"-->
                  <!--                    size="16"-->
                  <!--                    style="color:rgb(40,199,111);"-->
                  <!--                    @click="confirmAgreementApproveText(data.item.id)"-->
                  <!--                  />-->
                  <!--                  <feather-icon-->
                  <!--                    v-if="userData.type === 1 && data.item.status == 5 || userData.type === 2 && data.item.status == 6"-->
                  <!--                    :id="`agreement-row-${data.item.id}-disapprive-icon`"-->
                  <!--                    class="cursor-pointer ml-1"-->
                  <!--                    icon="XCircleIcon"-->
                  <!--                    size="16"-->
                  <!--                    style="color:rgb(234,84,85) !important;"-->
                  <!--                    @click="confirmAgreementDisapproveText(data.item.id)"-->
                  <!--                  />-->
                </div>
              </template>
            </b-table>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-card
          title=""
        >
          <b-row>
            <b-col
              cols="6"
              class="text-left"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                @click="$router.go(-1)"
              >
                <span>terug</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAlert,
  BBadge,
  BButton,
  BCard, BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BLink,
  BOverlay,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  formatDateTime,
  formatYearMothString,
  getPayPeriods,
} from '@core/utils/utils'
import 'flatpickr/dist/flatpickr.css'
import {
  between,
  date,
  email,
  integer,
  required,
} from '@core/utils/validations/validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'
import companiesStoreModule from '@/views/pages/companies/companiesStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useCompaniesList from '@/views/pages/companies/useCompaniesList'
import { heightTransition } from '@core/mixins/ui/transition'
import PdfViewer from '@/views/components/pdf-viewer/PdfViewer.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BTable,
    BOverlay,
    BAlert,
    vSelect,
    BBadge,
    BDropdown,
    BDropdownItem,
    PdfViewer,
    BSpinner,
    BLink,
    BCardText,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      email,
      date,
      integer,
      between,
      companyData: {
        commissionPercentage: 0,
      },
      invoices: [],
      agreements: [],
      showOverlay: false,
      showPaySlipsOverlay: false,
      showAgreementOverlay: false,
      startDateConfig: {
        minDate: 'today',
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      endDateConfig: {
        minDate: 'today',
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d',
        altInput: true,
        allowInput: true,
      },
      showAddAGreementSpinner: false,
      paycheckPeriodSelected: null,
      showChangePaycheckPeriodSpinner: false,
    }
  },
  beforeMount() {
    this.getCompany()
    this.fetchInvoices()
    this.fetchAgreements()
  },
  methods: {
    updateFee() {
      store
        .dispatch('app-company-edit/updateCompany', { id: this.companyData.id, commissionPercentage: this.companyData.commissionPercentage })
        .then(() => {
          this.getCompany()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Fee aanpassen',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het aanpassen van de fee. Probeer het nogmaals.',
            },
          })
        })
    },
    confirmApproveDeclineInvoice(status, type, id) {
      this.$swal({
        title: 'Weet je het zeker?',
        text: 'U kunt dit niet meer terugdraaien!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, factuur accepteren!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.approveInvoice(status, type, id)
          }
        })
    },
    approveInvoice(status, type, id) {
      const acceptData = { id }

      if (status === 11 && this.profileData.type === 1) {
        if (type === 1) {
          acceptData.isFreelancerApproved = 1
        } else if (type === 0) {
          acceptData.isFreelancerApproved = -1
        }
      }

      if (status === 12 && this.profileData.type === 2) {
        if (type === 1) {
          acceptData.isCompanyApproved = 1
        } else if (type === 0) {
          acceptData.isCompanyApproved = -1
        }
      }

      store
        .dispatch('app-company-edit/approveInvoice', acceptData)
        .then(() => {
          this.refetchData()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Factuur accepteren',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het opslaan van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    showModalPaycheckPeriode(id) {
      this.id = id
      this.$refs.modalChangePaycheckPeriodInvoice.show()
    },
    handleChangePaycheckPeriode() {
      this.showChangePaycheckPeriodSpinner = true
      const period = this.getPayPeriods()
        .filter(b => b.value === this.paycheckPeriodSelected)[0]

      store
        .dispatch('app-company-edit/patchInvoice', {
          paycheckPeriod: period.value,
          id: this.id,
        })
        .then(() => {
          this.showChangePaycheckPeriodSpinner = false
          this.$refs.modalChangePaycheckPeriodInvoice.hide()
          this.id = null
          this.fetchInvoices()
        })
        .catch(() => {
          this.showChangePaycheckPeriodSpinner = false
          this.$refs.modalChangePaycheckPeriodInvoice.hide()
          this.id = null
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Verloningsperiode wijzigen',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het aanpassen van de verloningsperiode. Probeer het nogmaals.',
            },
          })
        })
    },
    updateCompany() {
      store.dispatch('app-company-edit/updateCompany', this.companyData)
        .then(() => {
          this.$router.go(-1)
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Bedrijfsinformatie updaten',
            text: 'Er is een fout opgetreden bij het updaten. Probeer het later nog een keer.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    getCompany() {
      this.showOverlay = true
      store.dispatch('app-company-edit/fetchCompany', { id: router.currentRoute.params.id })
        .then(response => {
          this.showOverlay = false
          this.companyData = response.data
        })
        .catch(() => {
          this.showOverlay = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ophalen bedrijfsinformatie',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer later het nog een keer.',
            },
          })
        })
    },
    fetchInvoices() {
      this.showPaySlipsOverlay = true
      store
        .dispatch('app-company-edit/fetchInvoices', { id: router.currentRoute.params.id }, {
          sortDesc: true,
        })
        .then(response => {
          this.showPaySlipsOverlay = false
          this.invoices = response.data.items
        })
        .catch(() => {
          this.showPaySlipsOverlay = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ophalen facturen',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    fetchAgreements() {
      // this.showPaySlipsOverlay = true
      store
        .dispatch('app-company-edit/fetchAgreements', router.currentRoute.params.id, {
          sortDesc: true,
        })
        .then(response => {
          // this.showPaySlipsOverlay = false
          this.agreements = response.data.items
        })
        .catch(() => {
          // this.showPaySlipsOverlay = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ophalen facturen',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    viewDocument(data) {
      this.$store.commit('app/UPDATE_PDF_URL', `${axiosDefaultConfig.backendEndPoint}invoices/${data.id}/pdf?token=${data.pdfToken}`)
      this.$refs.modalPdfViewer.show()
    },
    viewAgreement(data) {
      this.$store.commit('app/UPDATE_PDF_URL', `${axiosDefaultConfig.backendEndPoint}agreements/${data.id}/pdf?token=${data.pdfToken}`)
      this.$refs.modalPdfViewer.show()
    },
  },
  setup() {
    const COMPANY_EDIT_APP_STORE_MODULE_NAME = 'app-company-edit'

    // Register module
    if (!store.hasModule(COMPANY_EDIT_APP_STORE_MODULE_NAME)) store.registerModule(COMPANY_EDIT_APP_STORE_MODULE_NAME, companiesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COMPANY_EDIT_APP_STORE_MODULE_NAME)) store.unregisterModule(COMPANY_EDIT_APP_STORE_MODULE_NAME)
    })

    const {
      tableInvoicesColumns,
      resolveTypeVariant,
      tableAgreementsColumns,
      agreementUnits,
      noticePeriods,
      sectorOptions,
      resolveTypeInvoiceVariant,
      resolveTypeAgreementVariant,
    } = useCompaniesList()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      formatYearMothString,
      formatDateTime,
      tableInvoicesColumns,
      resolveTypeVariant,
      tableAgreementsColumns,
      agreementUnits,
      noticePeriods,
      sectorOptions,
      resolveTypeInvoiceVariant,
      axiosDefaultConfig,
      getPayPeriods,
      resolveTypeAgreementVariant,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss" scoped>
.form-group.is-invalid {
  ::v-deep .form-control {
    border-color: #ea5455 !important;
  }
}
</style>
