import axios from '@axios'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAdminToken(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}users/${id}/admintoken`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompanies(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}companies`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompany(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}companies/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCompany(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${axiosDefaultConfig.backendEndPoint}companies/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCompany(ctx, company) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}companies`, company)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchCocRegister(ctx, coc) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}kvk?q=${coc.q}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchInvoices(ctx, data, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}invoices?companyID=${data.id}`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAgreements(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}agreements?freelancerID=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addAgreement(ctx, agreement) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}agreements`, agreement)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAgreement(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${axiosDefaultConfig.backendEndPoint}agreements/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    patchInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${axiosDefaultConfig.backendEndPoint}invoices/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    approveInvoice(ctx, invoice) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${axiosDefaultConfig.backendEndPoint}invoices/${invoice.id}`, invoice)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
